import React, { useState } from 'react';
import { Modal, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { Formik, FieldArray } from 'formik';
import { fieldEditorSchema } from '../validations/EditMeasureValidations';

function FieldEditorModal({ show, onClose, data, isDisabled, ...props }) {
  const getInitialValidations = (data) => {
    if (data.validations && data.validations.length > 0) {
      const normalizedValidations = data.validations.map(validation => ({
        ...validation,
        type: validation.type.toLowerCase() === "required" ? "required" : validation.type
      }));
      return normalizedValidations;
    }
    return [{ type: "required" }];
  };

  const handleFormSubmit = (values) => {
    let validations = [...values.validations];

    if (values.type === "Display") {
      validations = [{
        type: "not required",
        params: [`${values.name} is not required`]
      }];
    } else if (values.type !== "Display" && validations.length > 0 && validations[0].type !== "not required") {
      validations = validations.map(validation => ({
        ...validation,
        type: validation.type === "not required" ? "not required" : "required",
        params: [`${values.name} is required`]
      }));
    }

    const updatedValues = {
      ...values,
      validationType: ["Decimal", "Integer"].includes(values.type) ? "number" : "string",
      validations,
      label: values.type === "Display" ? "" : (values.label === "" ? values.name : values.label),
      options: values.type !== "DropDown" ? [] : values.options,
    };

    if (props.currentAction === "Edit") {
      props.handleUpdateField(data, updatedValues)
    } else {
      props.handleAddField(updatedValues)
    }

    onClose();
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
    >
      <Modal.Header className="modal__header">
        <h5>{props.currentAction} Field</h5>
        <button className="modal__header__btn" onClick={onClose} aria-label="Exit">X</button>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            name: data.name || '',
            type: data.type || '',
            as: data.as || '',
            label: data.label || '',
            placeholder: data.placeholder || '',
            defaultValue: data.defaultValue || '',
            minValue: data.minValue || '',
            maxValue: data.maxValue || '',
            options: data.options || [{ text: "", value: "" }],
            displayText: data.displayText || '',
            validations: getInitialValidations(data)
          }}
          validationSchema={fieldEditorSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors
          }) => {
            const handleToggleRequiredField = (event) => {
              const { checked } = event.target;
              const newValidationType = checked ? "required" : "not required";

              setFieldValue("validations", [{ type: newValidationType }]);
            };

            const handleToggleMultiLineTextField = (event) => {
              const { checked } = event.target;

              setFieldValue(
                "as",
                checked ? "textarea" : ""
              );
            }

            return (
              <Form>
                <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Field Name'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                    disabled={isDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Control
                    as='select'
                    className={`sure-impact__select ${values.type === '' ? 'sure-impact__select-default' : ''}`}
                    value={values.type}
                    onChange={handleChange}
                    name="type"
                    isInvalid={touched.type && !!errors.type}
                    disabled={isDisabled}
                  >
                    <option value="" className="sure-impact__select-default">Field Type</option>
                    <option value="Decimal">Decimal</option>
                    <option value="Integer">Integer</option>
                    <option value="Text">Text</option>
                    <option value="DropDown">Dropdown</option>
                    <option value="Display">Display</option>
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                </Form.Group>

                {values.type === "Display" ? (
                  <Form.Control
                    type='text'
                    as='textarea'
                    placeholder='Display Text'
                    className='mb-3'
                    name='displayText'
                    value={values.displayText}
                    onChange={handleChange}
                  />
                ) : (
                  <Form.Control
                    type='text'
                    placeholder='Label'
                    className='mb-3'
                    name='label'
                    value={values.label}
                    onChange={handleChange}
                  />
                )}

                <Form.Control
                  type='text'
                  placeholder='Placeholder'
                  className='mb-3'
                  name='placeholder'
                  value={values.placeholder}
                  onChange={handleChange}
                />

                <Form.Group className='mb-4'>
                  <Form.Control
                    type='text'
                    placeholder='Default Value'
                    name='defaultValue'
                    value={values.defaultValue}
                    onChange={handleChange}
                  />
                </Form.Group>

                {values.type === "Integer" || values.type === "Decimal" ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type='text'
                        placeholder='Minimum Value'
                        name='minValue'
                        value={values.minValue}
                        onChange={handleChange}
                        isInvalid={touched.minValue && !!errors.minValue}
                        disabled={isDisabled}
                      />

                      <Form.Control.Feedback type="invalid">{errors.minValue}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-5">
                      <Form.Control
                        type='text'
                        placeholder='Maximum Value'
                        name='maxValue'
                        value={values.maxValue}
                        onChange={handleChange}
                        isInvalid={touched.maxValue && !!errors.maxValue}
                        disabled={isDisabled}
                      />
                      <Form.Control.Feedback type="invalid">{errors.maxValue}</Form.Control.Feedback>
                    </Form.Group>

                  </>
                ) : values.type === "DropDown" ? (
                  <FieldArray name="options" value={values.options}>
                    {
                      (fieldArrayProps) => {
                        return (
                          <>
                            <h5 className='font-weight-bold mb-3'>Dropdown Options</h5>
                            {values.options?.map((option, index) => {
                              return (
                                <div className='d-flex align-items-baseline' key={index}>
                                  <Form.Group className='mb-2 w-100'>
                                    <Form.Control
                                      type='text'
                                      placeholder='Option'
                                      name={`options[${index}].text`}
                                      onChange={(event) => {
                                        const updatedOptions = [...values.options];
                                        updatedOptions[index] = {
                                          text: event.target.value,
                                          value: event.target.value,
                                        };
                                        setFieldValue('options', updatedOptions);
                                      }}
                                      value={values.options[index].text}
                                      isInvalid={touched.options && !!errors.options}
                                    />

                                    <Form.Control.Feedback type="invalid">{errors.options}</Form.Control.Feedback>
                                  </Form.Group>

                                  <button
                                    type='button'
                                    className='field__hyperlink__btn pl-3'
                                    onClick={() => fieldArrayProps.remove(index)}>
                                    Remove
                                  </button>
                                </div>
                              )
                            })}
                            <button
                              className='field__hyperlink__btn mb-3'
                              onClick={() => fieldArrayProps.push({ text: "", value: "" })}
                              type='button'
                            >
                              Add Another Option
                            </button>
                          </>
                        )
                      }
                    }
                  </FieldArray>
                ) : null
                }

                <Form.Group className="mb-5">
                  {values.type !== "Display" ? (
                    <Form.Check
                      type='checkbox'
                      label='Field is required'
                      checked={values.validations[0].type === "required"}
                      onChange={(event) => handleToggleRequiredField(event)}
                    />
                  ) : null
                  }

                  {values.type === "Text" ? (
                    <Form.Check
                      type="checkbox"
                      label="Field is multi-line"
                      checked={values.as === "textarea" ? true : false}
                      onChange={(event) => handleToggleMultiLineTextField(event)}
                      className="mt-2"
                    />
                  ) : null}
                </Form.Group>

                <ButtonToolbar className='d-flex justify-content-evenly'>
                  <Button variant='outline-primary' onClick={onClose}>Cancel</Button>
                  <Button variant='primary' type='button' onClick={handleSubmit}>Save Field</Button>
                </ButtonToolbar>
              </Form>
            )
          }
          }
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default FieldEditorModal;