export default class OrganizationDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationName = options.organizationName || '';
    this.licenseLevel = options.licenseLevel || 0;
    this.licenseType = options.licenseType || 0;
    this.isDisabled = options.isDisabled || false;
    this.activeDemographicsSchema = options.activeDemographicsSchema ? this.parseJson(options.activeDemographicsSchema) : null;
    this.draftDemographicsSchema = options.draftDemographicsSchema ? this.parseJson(options.draftDemographicsSchema) : null;
    this.mfaIsEnabled = options.mfaIsEnabled || false;

    this.isActive = !this.isDisabled; // reverse of isDisabled for UI purposes
  }

  parseJson(schema) {
    try {
      return JSON.parse(schema);
    } catch (err) {
      return null;
    }
  }

  stringify() {
    return JSON.stringify(this);
  }
}
