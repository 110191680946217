import React from 'react';
import { Form, Col, ButtonToolbar, Button, Dropdown, Spinner } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import InputMask from 'react-input-mask';

import MultiSelect from '../../shared/asyncSelect/MultiSelect';
import EditActions from '../../shared/EditActions';

function EditServiceActivityForm({ formSchema, handleFormSubmit, serviceActivity, searchClients, pageNum, pageSize, selectClients, handleCancelClick, userList, ...props }) {
    const checkIfCurrentUserIsSiteAdmin = props.currentUser.roles[0] === "SiteAdministrator" ? true : false;
    const defaultApplicationUserId = userList.length > 0 && !checkIfCurrentUserIsSiteAdmin ? userList[0].value : ""
    
    return (
        <Formik
            validationSchema={formSchema}
            onSubmit={handleFormSubmit}
            initialValues={{
                ...serviceActivity,
                applicationUserId: serviceActivity.applicationUserId || defaultApplicationUserId
            }}
            enableReinitialize={true}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                isSubmitting
            }) => (
                <Form onSubmit={handleSubmit} className="mt-3 mb-3">
                    <Form.Row>

                        <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="durationHours">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control
                                as="select"
                                className={`sure-impact__select ${values.durationHours === '' ? 'sure-impact__select-default' : ''}`}
                                value={values.durationHours}
                                onChange={handleChange}
                                isInvalid={touched.durationHours && !!errors.HrsOrMins}
                            >
                                <option value="">Hours</option>
                                {Array.from({ length: 25 }, (_, i) => (
                                    <option key={i} value={i}>{i}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.HrsOrMins}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="durationMinutes">
                            <Form.Label aria-label="Duration - Minutes">&nbsp;</Form.Label>
                            <Form.Control
                                as="select"
                                className={`sure-impact__select ${values.durationMinutes === '' ? 'sure-impact__select-default' : ''}`}
                                value={values.durationMinutes}
                                onChange={handleChange}
                                isInvalid={touched.durationMinutes && !!errors.HrsOrMins}
                            >
                                <option value="" defaultValue>Minutes</option>

                                {[...Array(60).keys()].map(value => {
                                    return <option key={value} value={value}>{value}</option>;
                                })}

                            </Form.Control>
                        </Form.Group>

                        <Col xs={1} />

                        <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="activityDate">
                            <Form.Label>Service Date</Form.Label>
                            <InputMask mask="99/99/9999" value={values.activityDate} onChange={handleChange} touched={touched} errors={errors}>
                                <Form.Control
                                    type="text"
                                    value={values.activityDate}
                                    onChange={handleChange}
                                    isInvalid={touched.activityDate && !!errors.activityDate}
                                />
                            </InputMask>
                            <Form.Control.Feedback type="invalid">
                                {errors.activityDate}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="activityTime">
                            <Form.Label>Service Time</Form.Label>
                            <InputMask mask={[/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /[AaPp]/, /[Mm]/]} maskPlaceholder="hh:mm tt" value={values.activityTime} onChange={handleChange} touched={touched} errors={errors}>
                                <Form.Control
                                    type="text"
                                    value={values.activityTime}
                                    onChange={handleChange}
                                    isInvalid={touched.activityTime && !!errors.activityTime}
                                />
                            </InputMask>
                            <Form.Control.Feedback type="invalid">
                                {errors.activityTime}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="notes">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Notes"
                                value={values.notes}
                                onChange={handleChange}
                                isInvalid={touched.notes && !!errors.notes}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.notes}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        {selectClients ?
                            <Form.Group as={Col} controlId="additionalClients">
                                <Form.Label>
                                    Add one or more clients to this Service Activity
                                </Form.Label>
                                <Field name="additionalClients" value={values.additionalClients}>
                                    {({ field, form }) => (
                                        <MultiSelect
                                            placeholder="Search clients"
                                            noOptionsMessage={() => 'No clients found'}
                                            closeMenuOnSelect={false}
                                            loadOptions={searchClients}
                                            additional={{ pageNum: pageNum, pageSize: pageSize }}
                                            onChange={(selectedClients) => form.setFieldValue(field.name, (selectedClients || []).map(c => c.value))}
                                        />
                                    )}
                                </Field>
                                <Form.Control.Feedback type="invalid" className={touched.additionalClients && !!errors.additionalClients ? 'd-block' : null}>
                                    {errors.additionalClients}
                                </Form.Control.Feedback>
                            </Form.Group>
                            : null}

                        <Form.Group as={Col}>
                            <Form.Label>Assign a user to this Service Activity</Form.Label>
                            <Form.Control
                                as="select"
                                placeholder='Select user to assign'
                                className={`sure-impact__select ${values.applicationUserId === '' ? 'sure-impact__select-default' : ''}`}
                                value={values.applicationUserId}
                                name="applicationUserId"
                                onChange={handleChange}
                                isInvalid={touched.applicationUserId && !!errors.applicationUserId}
                            >
                                { checkIfCurrentUserIsSiteAdmin && 
                                    <option value="" className="select-default">Select User</option> 
                                }
                                {userList.length === 0 ?
                                    <option value="">Loading Users...</option>
                                :
                                    userList.map(user => (
                                        <option key={user.value} value={user.value}>
                                            {user.label}
                                        </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.applicationUserId}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <EditActions>
                        <ButtonToolbar className="d-flex justify-content-end">
                            <Button variant="outline-primary" onClick={handleCancelClick}>Cancel</Button>
                            <Button variant="primary" type="submit" disabled={isSubmitting}>Save</Button>
                        </ButtonToolbar>
                    </EditActions>

                </Form>
            )}
        </Formik >
    )
}

export default EditServiceActivityForm
