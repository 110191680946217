import React, { useState, useEffect } from 'react';

import useFetchApplicationUsers from '../../shared/hooks/useFetchApplicationUsers';
import useClientSearch from '../custom hooks/useClientSearch';
import ServiceActivityDto from '../../../DTOs/ServiceActivity/ServiceActivityDto';
import { editServiceActivitySchema } from '../formValidations/editServiceActivityValidations';

import EditServiceActivityForm from './EditServiceActivityForm'
import { useSelector } from 'react-redux';

function EditServiceActivity({ serviceActivity, selectClients, handleCancelClick, requireClients, ...props }) {
  const { fetchActiveStaff } = useFetchApplicationUsers();
  const [applicationUsers, setApplicationUsers] = useState([]);
  const { pageNum, pageSize, searchAllClients } = useClientSearch();
  const formSchema = editServiceActivitySchema(requireClients);
  const currentUser = useSelector((state) => state.user)

  const handleFormSubmit = (values, actions) => {
    const dto = new ServiceActivityDto({
      ...values,
      durationHours: values.durationHours === '' ? '0' : values.durationHours,
      durationMinutes: values.durationMinutes === '' ? '0' : values.durationMinutes
    });

    dto.setActivityDateAndTimeZone(values.activityDate, values.activityTime);

    props.handleFormSubmit(dto, actions);
  }

  useEffect(() => {
    const setAvaiableUsers = async () => {
      let userList = await fetchActiveStaff();
      let loggedInUser = {
        label: `${currentUser.firstName}, ${currentUser.lastName}`,
        value: currentUser.id
      };

      if (currentUser.roles[0] !== "SiteAdministrator") {
        userList = userList.filter(user => user.value !== loggedInUser.value)
        userList = [loggedInUser, ...userList];
      }
  
      if (serviceActivity.applicationUserId > 0) {
        let defaultUser = {
          label: serviceActivity.staffName,
          value: serviceActivity.applicationUserId
        }

        userList = userList.filter(user => user.value !== defaultUser.value)
        userList = [defaultUser, ...userList]
      }

      setApplicationUsers(userList);
    }

    setAvaiableUsers();
  }, [])

  return (
    <EditServiceActivityForm
      formSchema={formSchema}
      handleFormSubmit={handleFormSubmit}
      serviceActivity={serviceActivity}
      searchClients={searchAllClients}
      pageNum={pageNum}
      pageSize={pageSize}
      handleCancelClick={handleCancelClick}
      selectClients={selectClients}
      requireClients={requireClients}
      userList={applicationUsers}
      currentUser={currentUser}
    />
  );
}



EditServiceActivity.defaultProps = {
  requireClients: false,
  selectClients: false,
  serviceActivity: {},
  handleCancelClick: () => { },
  handleFormSubmit: () => { }
}

export default EditServiceActivity;