import { apiCall } from "../api";
import EmbedParametersDto from '../../DTOs/PowerBiEmbedded/EmbedParametersDto';
import ExternalAnalyticsListDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsListDto';

export const getEmbedParameters = async (id) => {
  let url = 'externalanalytics/EmbedParameters/';

  if (id !== undefined) {
    url = `externalanalytics/EmbedParameters/${id}`;
  }

  let response = await apiCall(url);
  return new EmbedParametersDto(response.result);
}

export const getAnalyticsForOrg = async () => {
  let url = 'externalanalytics/';
  let response = await apiCall(url);
  return new ExternalAnalyticsListDto(response.result);
}
