import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import EditServiceActivity from '../clients/serviceActivity/EditServiceActivity.js';
import { message } from '../shared/Message';

import api from '../../scripts/api';
import ServiceActivityDto from '../../DTOs/ServiceActivity/ServiceActivityDto';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants';
import { hasRequiredPermissions } from '../../scripts/permissionHelper.js';

const editPermissions = {
  rolePermission: rolePermissionLevels.ORG_ADMIN,
  licenseLevelPermission: licenseLevels.PAID,
  licenseTypePermission: [licenseTypes.SPONSOR, licenseTypes.INDIVIDUAL]
};

export class DisplayService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addToClientsVisible: false
    }

    this.errorMessageId = null;

    this.toggleAddActivityToClients = this.toggleAddActivityToClients.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
  }

  toggleAddActivityToClients() {
    this.setState({
      addToClientsVisible: !this.state.addToClientsVisible
    });
  }

  saveActivity(dto, actions) {
    message.dismiss(this.errorMessageId);

    api.addServiceActivity(dto.stringify()).then(() => {
      this.setState({
        addToClientsVisible: false
      });
    }).catch(err => {
      console.log(`Error submitting service activity: ${err}`);
      this.errorMessageId = message.error(`Error submitting service activity: ${err}`);
    }).finally(() => {
      actions.setSubmitting(false);
    })
  }

  render() {
    const { addToClientsVisible } = this.state;
    const { service: { name, description, isDisabled, id }, handleEditClick, userPermissions } = this.props;

    return (
      <React.Fragment>
        <Row className="mb-3">
          <Col lg={8}>
            <div className="mb-3">
              <h5>{name}</h5>
              <span className="font-italic display-component__not-active">{isDisabled ? 'Not Active' : 'Active'}</span>
            </div>
            <div>
              {description}
            </div>
          </Col>
          <Col lg={4} className="d-flex flex-lg-column justify-content-lg-start justify-content-end align-items-end">
            {hasRequiredPermissions(editPermissions, userPermissions) &&
              <Button variant="primary" disabled={addToClientsVisible} className="mb-lg-3 mr-3 mr-lg-0" onClick={handleEditClick}>Edit Service</Button>
            }
            <Button variant="outline-primary" onClick={this.toggleAddActivityToClients}>Add Activity to Client(s)</Button>
          </Col>
        </Row>
        {addToClientsVisible ?
          <EditServiceActivity
            serviceActivity={new ServiceActivityDto({ organizationServiceId: id })}
            handleFormSubmit={this.saveActivity}
            handleCancelClick={this.toggleAddActivityToClients}
            selectClients
            requireClients
          />
        : null }
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ userPermissions: state.user.permissions })
)(DisplayService);
