import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

export default class Environment extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

    return (environment !== 'Production'
      ? <Alert className="environment-component-banner d-print-none" variant="secondary">{environment} Environment </Alert>
      : null);
  }
}
