import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from '../shared/Message';
import { getEmbedParameters } from '../../scripts/api/analyticsApi';
import { PowerBIEmbed, EmbedType } from 'powerbi-client-react';
import { models } from 'powerbi-client';

function AnalyticsReportIndex() {
  const [ embedConfig, setEmbedConfig ] = useState(null);

  const { id } = useParams();
  const currentUser = useSelector(state => state.user);
  const { effectiveOrganizationId, organizationId } = currentUser;

  const getFilterTarget = (target, orgId) => ({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: target.table,
      column: target.column
    },
    operator: "In",
    values: [orgId]
  });

  useEffect(() => {
    const fetchAnalytics = async (id) => {
      try {
        let response = await getEmbedParameters(id);
        let orgId = effectiveOrganizationId;

        if (!orgId) {
          orgId = organizationId;
        }

        const FILTER_TARGETS = [
          { table: 'ROLLUP_ORGANIZATION_DIMENSION', column: 'Rollup Organization Id' }
        ];

        const filters = FILTER_TARGETS?.map((target) => getFilterTarget(target, orgId));

        setEmbedConfig({
          type: EmbedType.Report,
          id: response.embedReport.reportId,
          embedUrl: response.embedReport.embedUrl,
          accessToken: response.embedToken.token,
          tokenType: models.TokenType.Embed,
          filters: filters,
          settings: {
            filterPaneEnabled: false,
          }
        });
      }
      catch (err) {
        message.error(`Error getting report: ${err.message}`);
      }
    }

    fetchAnalytics(id);
  }, [id, effectiveOrganizationId, organizationId, embedConfig]);

  return (
    <>
      {
        embedConfig ?
          <PowerBIEmbed embedConfig={embedConfig} cssClassName="pbi-report" />
          : null
      }
    </>
  );
}

export default AnalyticsReportIndex;
