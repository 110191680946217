import React from 'react';
import { PickList } from 'primereact/picklist';
import { ProgressSpinner } from 'primereact/progressspinner';

function PickListSelect({ source, target, addToTarget, removeFromTarget }) {
    const sourceItemTemplate = (item) => {
        return (
            <div className='d-flex justify-content-between' onClick={() => addToTarget(item)}>
                <div>
                    <strong>{item.label}</strong>
                    <div>
                        { item.birthDate && <p>DOB: {item.birthDate}</p> }
                    </div>
                </div>
                    <i className='pi pi-plus-circle align-self-center' style={{fontSize: '1.2rem', color: 'slateblue'}}></i>
            </div>
        );
    };

    const targetItemTemplate = (item) => {
        return (
            <div className='d-flex justify-content-between' onClick={() => removeFromTarget(item)}>
                <div>
                    <strong>{item.label}</strong>
                    <div>
                        { item.birthDate && <p>DOB: {item.birthDate}</p> }
                    </div>
                </div>
                <i className='pi pi-minus-circle align-self-center' style={{fontSize: '1.2rem', color: 'slateblue'}}></i>
            </div>
        )
    }
  
    return (
        <div className="card">
            { !source && !target ?
                <ProgressSpinner />
                :
                <PickList
                    className='p-picklist-custom'
                    source={source}
                    target={target}
                    sourceHeader="Available"
                    targetHeader="Selected"
                    sourceItemTemplate={sourceItemTemplate}
                    targetItemTemplate={targetItemTemplate}
                    breakpoint="800px"
                    filter 
                    filterBy="label"
                    sourceFilterPlaceholder="Search"
                    targetFilterPlaceholder="Search"
                    sourceStyle={{ height: '30rem' }} 
                    targetStyle={{ height: '30rem' }}
                    showSourceControls={false}
                    showTargetControls={false}
                />
            }
        </div>
    )
}

export default PickListSelect;